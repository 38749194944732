<template>
	<b-modal id="edit-storage-location" title="Edit Storage Location" ref="modal" ok-title="Update" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true" hide-header-close>
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-form-group label="Company">
					<b-form-input id="name" name="Name" type="text" v-model="form.company" v-validate="'required'"
						placeholder readonly />
				</b-form-group>

				<b-form-group label="Name" label-for="name" description>
					<b-form-input id="name" name="Name" type="text" v-model="form.name" v-validate="{
						required: true,
						regex: locationNameRegex,
					}" placeholder="Name" readonly />
					<span v-show="errors.has('Name')" class="help-block">{{
						errors.first('Name')
					}}</span>
				</b-form-group>

				<b-form-group label="Description" label-for="description" description="">
					<b-form-input id="description" name="Description" type="text" v-model="form.description" v-validate="{
						required: false,
						regex: remarksRegex,
					}" placeholder="Description" maxlength="200" />
					<span v-show="errors.has('Description')" class="help-block">
						{{ errors.first('Description') }}
					</span>
				</b-form-group>

				<b-form-group label="Address" label-for="address"
					description="Please enter the complete address of the storage location">
					<b-form-input id="address" name="Address" type="text" v-model="form.address" v-validate="{
						required: true,
						regex: addressRegex,
					}" required placeholder="Address" />
					<span v-show="errors.has('Address')" class="help-block">{{
						errors.first('Address')
					}}</span>
				</b-form-group>

				<b-form-group label="Geographical Location" label-for="geoaddress"
					description="This is the exact coordinates that will be used to locate in the map">
					<b-row class="my-2">
						<b-col lg="6" md="6" sm="12">
							<b-form-group description="Latitude">
								<b-form-input id="latitude" name="Latitude" type="number" class="numFont"
									v-model="form.geoaddress.latitude"
									v-validate="'required|min_value:-90|max_value:90'" min="-90" max="90" step="0.01"
									placeholder="0" @keydown="blockInvalidCharacters" />
							</b-form-group>
							<span v-show="errors.has('Latitude')" class="help-block">{{
								errors.first('Latitude')
							}}</span>
						</b-col>
						<b-col lg="6" md="6" sm="12">
							<b-form-group description="Longitude">
								<b-form-input id="longitude" name="Longitude" type="number" class="numFont"
									v-model="form.geoaddress.longitude"
									v-validate="'required|min_value:-180|max_value:180'" min="-180" max="180"
									step="0.01" placeholder="0" @keydown="blockInvalidCharacters" />
							</b-form-group>
							<span v-show="errors.has('Longitude')" class="help-block">{{
								errors.first('Longitude')
							}}</span>
						</b-col>
						<b-col lg="6" md="6" sm="12">
							<b-link href="https://www.latlong.net/" target="_blank">
								<i class="icon-info"></i>&nbsp;Generate coordinates here
							</b-link>
						</b-col>
					</b-row>
				</b-form-group>
				<b-form-group label="Area Radius (m)" label-for="areaRadius"
					description="Please enter the allowed area's radius relative to the locations coordinates.">

					<b-form-input id="areaRadius" name="Area Radius" type="number" class="numFont"
						v-model="form.areaRadius" v-validate="'required|min_value:10|max_value:1000'" min="10"
						max="1000" step="1" onwheel="this.blur()" placeholder="Area Radius" />

					<span v-show="errors.has('Area Radius')" class="help-block">{{
						errors.first('Area Radius')
					}}</span>
				</b-form-group>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { LocationUtil } from '@/utils/locationUtil';
import { StorageLocationUtil } from '@/utils/storageLocationUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// API
import storageLocationApi from '@/api/storageLocationApi';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import firebase from 'firebase/app';
import _ from 'lodash';

export default {
	name: 'edit-storage-location',
	components: {
		Loading,
	},
	props: {
		companyOptions: {
			type: Array,
			required: true,
		},
		allCompaniesObj: {
			type: Object,
			required: true,
		},
		allStorageLocationsObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			form:  { ...StorageLocationUtil.getDefaultStorageLocationObj() },
			selCompany: { ...config.companyDefaultValue },

			loggedUser: this.$store.getters.loggedUser,

			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		selCompany: function () {
			this.updateCompanySelection();
		},
		'form.areaRadius': function () {
			this.form.areaRadius = parseFloat(this.form.areaRadius);
		}
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		name() {
			return this.form.name;
		},
		locationNameRegex() {
			return config.locationNameRegex;
		},
		remarksRegex() {
			return config.remarksRegex;
		},
		addressRegex() {
			return config.addressRegex;
		}
	},
	methods: {
		blockInvalidCharacters(event) {
            // Check if the key pressed corresponds to one of the blocked characters
            if (event.key === '+' || event.key === '-' || event.key === 'e') {
                // Prevent the default behavior (typing the character)
                event.preventDefault();
            }
        },
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			if (!this.selCompany.id) {
				this.$toaster.warning('Please select the company of this storage location.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			if (StorageLocationUtil.exceedMaximum(this.allCompaniesObj[this.selCompany.id], this.allStorageLocationsObj, this.form)) {
				this.$toaster.warning('You have exceeded the allowed no of active storage location for this company.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			if (ValidationUtil.objectHasField('name', this.allStorageLocationsObj) && this.form.name != this.$store.getters.currStorageLocation.name) {
				this.$toaster.warning('Storage location name already exists.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			// Removes excess whitespace
			this.form.address = ValidationUtil.removeExcessWhiteSpace(this.form.address);

			await this.handleSubmit();
		},

		getStorageLocationObj(param) {
			// pre-process geoaddress
			if (!_.isEmpty(param.geoaddress)) {
				let latitude = LocationUtil.getLatitude(param.geoaddress);
				let longitude = LocationUtil.getLongitude(param.geoaddress);

				param.latitude = latitude;
				param.longitude = longitude;
				param.geoaddress = new firebase.firestore.GeoPoint(
					parseFloat(latitude),
					parseFloat(longitude)
				);
			}

			// update timestamp
			param.dateUpdated = DateUtil.getCurrentTimestamp();
			param.updatedBy = this.loggedUser.id;

			return { ...param };
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let { data } = await storageLocationApi.saveStorageLocation(
					this.getStorageLocationObj(this.form),
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				if (data.isSuccess) {
					this.$toaster.success(`Storage Location "${this.name}" was updated successfully.`);

					let result = {
						storageLocation: data.storageLocation,
						connection: data.connection,
						connections: data.connections
					};
					EventBus.$emit('onCloseSaveStorageLocation', result);
					this.$refs.modal.hide();

				} else {
					this.$toaster.error(`Error updating storage location "${this.name}". Please try again.`);
				}
			} catch (_error) {
				this.$toaster.error(`Error updating storage location "${this.name}". Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;

		},
		updateCompanySelection() {
			if (!_.isEmpty(this.selCompany)) {
				this.form.company = this.selCompany.name;
				this.form.companyId = this.selCompany.id;
			}
		},
		onReset() {
			/* Reset our form values */
			this.form = { ...this.$store.getters.currStorageLocation };
			this.form = StorageLocationUtil.cleanupFields(this.form);

			// pre-process geopoint
			this.form.geoaddress = {
				latitude: LocationUtil.getLatitude(this.form.geoaddress),
				longitude: LocationUtil.getLongitude(this.form.geoaddress),
			};

			// init company
			this.selCompany = {
				id: this.form.companyId,
				name: this.form.company,
			};

			// init area radius
			this.form.areaRadius = this.form.areaRadius;

			// reset validation
			this.isLoading = false;
			this.$validator.reset();
			this.errors.clear();
		},
	},
};
</script>