import { db } from '@/config/firebase';

async function getConnection(companyId, connectedCompanyId) {
    let connectionObj = {};
    const query = db.collection('connections')
        .where("company.id", "==", companyId)
        .where("connectedCompany.id", "==", connectedCompanyId)
        .limit(1);
    const querySnapshot = await query.get();

    querySnapshot.forEach((doc) => {
        connectionObj = doc.data();
    });

    return connectionObj;
}

async function getConnectionsByCompany(companyId) {
    let connectionsObj = {}
    const query = db.collection('connections')
        .where("companyId", "==", companyId);
    const querySnapshot = await query.get();

    querySnapshot.forEach(function (doc) {
        let connection = doc.data();
        connection['id'] = doc.id;
        connectionsObj[doc.id] = connection;
    });

    return connectionsObj;
}

async function getConnectionsByConnectedCompany(connectedCompanyId) {
    let connectionsObj = {}
    const query = db.collection('connections')
        .where("connectedCompanyId", "==", connectedCompanyId);
    const querySnapshot = await query.get();

    querySnapshot.forEach(function (doc) {
        let connection = doc.data();
        connection['id'] = doc.id;
        connectionsObj[doc.id] = connection;
    });

    return connectionsObj;
}

async function getAllConnectionTypes() {
    let connectionTypes = {};

    const dbRef = db.collection('connectionTypes').orderBy("name");
    const querySnapshots = await dbRef.get();

    querySnapshots.forEach(doc => {
        const id = doc.id;
        let connectionType = doc.data();
        connectionTypes[id] = {
            id: id,
            ...connectionType
        }
    });

    return connectionTypes;
}

export default {
    getConnection,
    getConnectionsByCompany,
    getConnectionsByConnectedCompany,
    getAllConnectionTypes
}